import { SANITY_API_VERSION, SANITY_DATASET_NAME, SANITY_PROJECT_ID } from 'config';
import getClient from '@sanity/client';

const client = getClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET_NAME,
  apiVersion: SANITY_API_VERSION,
  useCdn: true,
});

export default client;
