import sanityClient from 'lib/clients/sanity';
import imageUrlBuilder from '@sanity/image-url';
import { BREAK_POINTS_PX } from 'lib/theme';

const builder = imageUrlBuilder(sanityClient);

const getImage = (baseAsset, customDimensions) => {
  if (!baseAsset) return null;
  const result = {};

  const { crop, ...rest } = baseAsset;
  const asset = {
    ...rest,
    crop,
  };

  result.small = builder.image(asset).width(BREAK_POINTS_PX[0]).quality(70).auto('format').url();
  result.medium = builder.image(asset).width(BREAK_POINTS_PX[1]).quality(70).auto('format').url();
  result.large = builder.image(asset).width(BREAK_POINTS_PX[2]).quality(80).auto('format').url();
  result.xLarge = builder.image(asset).width(2560).quality(80).auto('format').url();

  result.srcSet = `${result.small} 480w, ${result.medium} ${BREAK_POINTS_PX[0]}w, ${result.large} ${BREAK_POINTS_PX[1]}w`;

  if (customDimensions) {
    result.custom = builder.image(asset).width(customDimensions.width).height(customDimensions.height).quality(70).auto('format').url();
  }

  return result;
};

export default getImage;
